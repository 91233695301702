import { useToast, PluginOptions } from 'vue-toastification'

type ToastFn = (message: string, options?: PluginOptions) => void

interface Toast {
  pushSuccessToast: ToastFn
  pushErrorToast: ToastFn
  pushInfoToast: ToastFn
  clearAllToasts: () => void
}

/**
 * use when toast message is needed
 * @description use inside component or store.
 * @returns pushSuccessToast, pushErrorToast, pushInfoToast, clearAllToasts
 */
export const useToastNotification = (): Toast => {
  const { success, error, info, clear } = useToast()

  /**
   * @description push turquise toast message
   * @param message string to be dispayed inside toast
   * @param options? additional options if you need to overwrite global settings
   * @example
   * ```typescript
   *  import { useToastNotification } from '@/modules/base/composable/useToastNotification'
   * const { pushSuccessToast } = useToastNotification()
   *  pushSuccessToast("My success content", {
        timeout: 2000
      });
   * ```
   */
  const pushSuccessToast = (message: string, options?: PluginOptions) =>
    success(message, options)
  /**
   * @description push red toast message
   * @param message string to be dispayed inside toast
   * @param options? additional options if you need to overwrite global settings
   * @example
   * ```typescript
   *  import { useToastNotification } from '@/modules/base/composable/useToastNotification'
   * const { pushErrorToast } = useToastNotification()
   *  pushErrorToast("My error content", {
        timeout: 2000
      });
   * ```
   */
  const pushErrorToast = (message: string, options?: PluginOptions) =>
    error(message, options)
  /**
   * @description push white toast message
   * @param message string to be dispayed inside toast
   * @param options? additional options if you need to overwrite global settings
   * @example
   * ```typescript
   *  import { useToastNotification } from '@/modules/base/composable/useToastNotification'
   * const { pushInfoToast } = useToastNotification()
   *  pushInfoToast("My info content");
   * ```
   */
  const pushInfoToast = (message: string, options?: PluginOptions) =>
    info(message, options)

  const clearAllToasts = () => clear()

  return {
    pushSuccessToast,
    pushErrorToast,
    pushInfoToast,
    clearAllToasts,
  }
}
